<!-- eslint-disable no-restricted-globals -->
<template>

      <!-- MODAL -->
      <Modal
      :is-open="modalData.isOpen"
      :icon-src="modalData.iconSrc"
      :title="modalData.title"
      :text="modalData.text"
      :confirm-button-class="modalData.confirmButtonClass"
      :confirm-button-text="modalData.confirmButtonText"
      :show-cancel-button="modalData.showCancelButton"
      @confirm-action="modalData.confirmAction"
      @cancel-action="modalData.cancelAction"
    />
    <!-- MODAL -->

    <!-- MODAL UBICACIONES -->
    <ModalUbicaciones
      :is-open="modalUbicacionesData.isOpen"
      :icon-src="modalUbicacionesData.iconSrc"
      :title="modalUbicacionesData.title"
      :text="modalUbicacionesData.text"
      :confirm-button-class="modalUbicacionesData.confirmButtonClass"
      :confirm-button-text="modalUbicacionesData.confirmButtonText"
      :show-cancel-button="modalUbicacionesData.showCancelButton"
      :group-list="locGroups"
      @confirm-action="modalUbicacionesData.confirmAction"
      @cancel-action="modalUbicacionesData.cancelAction"
    />

  <!-- ----------------------- -->
  <!-- PANEL BLANCO CARGADORES -->
  <div class="panel-cargadores">
  <div class="flex">

  <!-- panel-row -->
  <div class="panel-row">
  <div class="panel-celda-icono"><img id="ico-cargador" src="assets/img/power.svg"
      class="iconoPanel"></div>

  <div class="panel-titulo">{{$t('devicesTable.title')}}</div>

  <div v-if="vista==='Super'" class="panel-menu">
        <img
      id="menuAnadirIconoPlus"
      :title="$t('devicesTable.add')"
      :alt="$t('devicesTable.add')"
      src="/assets/img/ico-more.svg"
      class="tr-ico"
      @click="muestraAnadirMenu"
    />

    <img
      id="menuAnadirIconoLess"
      :title="$t('devicesTable.add')"
      :alt="$t('devicesTable.add')"
      src="/assets/img/ico-less.svg"
      class="tr-ico ico-activo none"
      @click="ocultaAnadirMenu"
    />

  <!-- ---------------------------------- -->
<!-- ---------- MENU ANADIR ----------  -->
<div id="menu-anadir" class="menu-anadir none" @mouseleave="ocultaAnadirMenu();">

<!-- anadir cargador -->
<div id="anadirCargador" class="menu-anadir-row n1" @click="mostrarAnadirCargador">
  {{$t('devicesTable.addCharger')}}
  <img title="" alt="" src="/assets/img/ico-continuar.svg" class="tr-ico ico-menu-continuar">
  <img :title="$t('devicesTable.cancel')" :alt="$t('devicesTable.cancel')"
  src="/assets/img/ico-close.svg"
    class="tr-ico ico-menu-aspa">
</div>
<div id="numeroDeSerie" class="menu-anadir-row n2 none"><input :ref="'inputSerialNumber'"
    id="inputSerialNumber"
    @input="checkSerialNumberLength"
    @keyup.enter="confirmaAgregarCargador"
    v-model="newChargerSN" maxlength="18" type="text" class="menu-input"
    :placeholder="$t('devicesTable.serialNumberPlaceholder')"
    autocomplete="off" />

  <img @click="confirmaAgregarCargador" id="iconoEnviarSN"
    class="tr-ico ico-activo"
    :class="(this.newChargerSN.length > this.newChargerSNMinLength) ? '' : 'invisible'"
    :title="$t('devicesTable.send')" :alt="$t('devicesTable.send')"
    src="/assets/img/ico-OK.svg">

</div>
<!-- /anadir cargador -->

<!-- anadir ubicacion -->
<div id="crearUbicacion" class="menu-anadir-row n1" @click="mostrarCrearUbicacion">
  {{$t('devicesTable.createGroup')}}
  <img title="" alt="" src="/assets/img/ico-continuar.svg" class="tr-ico ico-menu-continuar">
  <img :title="$t('devicesTable.cancel')" :alt="$t('devicesTable.cancel')"
    src="/assets/img/ico-close.svg"
    class="tr-ico ico-menu-aspa">
</div>
<div id="ubicacion" class="menu-anadir-row n2 none"><input
    id="inputNombreUbicacion"
    @input="checkcrearUbicacionLength"
    @keyup.enter="crearUbicacion"
    v-model="newGroupName" maxlength="18" type="text" class="menu-input"
    :placeholder="$t('devicesTable.groupNamePlaceholder')"
    autocomplete="off" />

  <img @click="crearUbicacion" id="iconoCrearUbicacion"
    class="tr-ico ico-activo"
    :class="(this.newGroupName.length > this.newGroupNameMinLength) ? '' : 'invisible'"
    :title="$t('devicesTable.createGroup')" :alt="$t('devicesTable.createGroup')"
      src="/assets/img/ico-OK.svg">

</div>
<!-- /anadir ubicacion -->

</div>
<!-- ---------- /MENU ANADIR ----------  -->
<!-- ----------------------------------- -->

  </div><!-- /panel-menu -->
  </div><!-- /panel-row -->

  </div>

  <!-- ----------------------------- -->
  <!-- NUEVA TABLA DE DATOS con grid -->

  <!-- CABECERA DE TABLA -->
  <div class="gridDatos gridDatosChargers cabecera">
  <div>
  <input  type="Checkbox" ref="checkAll"
  @click="onSelectAll"
  test-data="checkAllDevices"
  name="0device" id="0device" :checked="areAllDevicesSelected">
    <label for="0device"></label><strong>&nbsp;&nbsp;
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{$t('devicesTable.name')}}
    </strong></div>
  <div><strong>&nbsp;&nbsp;{{$t('devicesTable.id')}}</strong></div>
  <div><strong>&nbsp;&nbsp;&nbsp;&nbsp;{{$t('devicesTable.type')}}</strong></div>

  <div>&nbsp;</div>
  </div>
  <!-- /CABECERA DE TABLA -->

  <!-- CONTENEDOR DE TRS DE DATOS -->
  <div class="gridDatosContainer">

  <div>
    <!-- ITERATE OVER LOCATION GROUPS -->
    <template v-if="vista==='Super'">
  <div v-for="locGroup in locGroupsView" :key="locGroup.id" :id="'ubigr' + locGroup.id"
    class="carpetaUbicacion colapsada" >
    <!-- ubicacion header -->
    <div class="gridDatos gridDatosChargers">
      <div>
        <input type="Checkbox" @click="onClickUBI" :name="'UBI' + locGroup.id"
        :value="'UBI' + locGroup.id" :checked="areAnyDevicesInGroupSelected(locGroup)"
          :id="'UBI' + locGroup.id">
        <label :for="'UBI' + locGroup.id"></label>

        <input :id="'InputNameUBI'+locGroup.id" class="ubiNameInput"
          v-model="locGroup.name" maxlength="20"
          @keyup.enter="confirmaEditarNombreUbiAndBlur(locGroup.id, locGroup.name)"
          :ref="'nameUbiInputRef' + locGroup.id"
          @blur="confirmaEditarNombreUbi(locGroup.id, locGroup.name)" autocomplete="off"
          :style="{ width: calculateInputWidth(locGroup.name) + 'ch' }"
          >

        <span class="nota">({{ locGroup.devices ? locGroup.devices.length : 0 }})</span>
      </div>
      <div></div>
      <div></div>
      <div class="icos">
        <img @click="confirmaEliminarUbicacion(locGroup)"
        :title="$t('devicesTable.undoGrouping')"
          :alt="$t('devicesTable.undoGrouping')" src="/assets/img/ico-trash.svg"
          class="ico soloHover">
        <img :src="locGroup.isCollapsed ?
        '/assets/img/ico-expandmore.svg' : '/assets/img/ico-expandless.svg'"
          @click="desplegarUbicacion('ubigr'+locGroup.id)" :title="$t('devicesTable.expand')"
          :alt="$t('devicesTable.expand')"
          class="ico">
      </div>
    </div>

    <!-- cargadores en ubicacion -->
    <template v-if="locGroup.devices && locGroup.devices.length > 0">
      <div v-for="deviceKey in locGroup.devices" :key="deviceKey"
        class="gridDatos gridDatosChargers">
        <div>
          <input type="Checkbox" :value="deviceKey" :checked="isSelected(deviceKey)"
            @click="onClick" :name="'cb' + deviceKey" :id="'cb' + deviceKey">
          <label :for="'cb' + deviceKey"></label>
          <input :id="'nameInput'+deviceKey" class="chargerNameInput"
            :value="getDeviceName(deviceKey)" maxlength="20"
            :ref="'nameInputRef'+deviceKey"
            @keyup.enter="confirmaEditarNombreAndBlur2(deviceKey)"
            @blur="confirmaEditarNombre2(deviceKey)" autocomplete="off">
            <!-- <img  @click="confirmaEditarNombre2(deviceKey)"
                :title="$t('devicesTable.editChargerName')"
                :alt="$t('devicesTabloeditChargerName')"
                src="/assets/img/ico-OK.svg" class="ico soloHover"> -->
        </div>
        <div>{{ getDeviceIdent(deviceKey) }}</div>
        <div :id="'deviceDIV' + deviceKey">
          <img :src="getDeviceImgSrc(deviceKey)" :alt="$t('devicesTable.deviceImage')"
          class="devicePic">
          &nbsp;{{ getDeviceType(deviceKey) }}
        </div>

        <div :id="'icosDIV' + deviceKey" class="icos">
          <!-- iconos -->
          <img v-if="vista==='Super'"
            @click="confirmaEliminarCargador(deviceKey,getDeviceIdent(deviceKey))"
            :title="$t('devicesTable.deleteCharger')"
            :alt="$t('devicesTable.deleteCharger')" src="/assets/img/ico-trash.svg"
            class="ico soloHover">
          <img @click="confirmaSacarDeUbicacion(deviceKey,locGroup)"
            :title="$t('devicesTable.removeFromGroup')" :alt="$t('devicesTable.removeFromGroup')"
            src="/assets/img/ico-sacar.svg" class="ico soloHover">
          <!-- /iconos -->
        </div>
      </div>
    </template>
    <!-- /cargadores en ubicacion -->
  </div>
</template>

    <!-- /ITERATE OVER LOCATION GROUPS -->

    <!-- ITERATE OVER PENDING CHARGERS -->
<template v-for="pendingCharger in pendingDevices" :key="pendingCharger.docID">
  <!-- cargador inhabilitado -->
  <div v-if="vista==='Super'" class="gridDatos gridDatosChargers disabled">
    <div>
      <input disabled type="Checkbox" name="disabled" :id="'pendingCharger_'+pendingCharger.docID"
        class="invisible">
      <label :for="'pendingCharger_'+pendingCharger.docID" class="invisible"></label>
      {{ pendingCharger.name }}
    </div>
    <div>{{ $t('devicesTable.requested') }} </div>
    <div>{{ formatDate(pendingCharger.timestamp) }}</div>
    <div class="icos">
      <img v-if="vista==='Super'" @click="confirmaEliminarPendingCharger(pendingCharger)"
        :title="$t('devicesTable.deleteCharger')" :alt="$t('devicesTable.deleteCharger')"
        src="/assets/img/ico-trash.svg" class="ico soloHover">
      <img v-if="false" @click="confirmaEnviarRecordatorio(pendingCharger.docID)"
        :title="$t('devicesTable.remindApproval')" :alt="$t('devicesTable.remindApproval')"
        src="/assets/img/ico-remind.svg" class="ico soloHover">
    </div>
  </div>
  <!-- /cargador inhabilitado -->
</template>
<!-- /ITERATE OVER PENDING CHARGERS -->

<!-- ITERATE OVER DEVICES WITHOUT LOCATION -->
<template v-for="device in devicesWithoutLocation" :key="device.docID" test-data="device">
  <!-- cargador fuera de ubicacion -->
  <div class="gridDatos gridDatosChargers">
    <div>
      <input type="Checkbox" :value="device.docID" :checked="isSelected(device.docID)"
        @click="onClick" :name="'cb'+device.docID" :id="'cb'+device.docID">
      <label :for="'cb'+device.docID"></label>
       <input :id="'nameInput'+device.docID" class="chargerNameInput"
        v-model="device.name" maxlength="20"
        @keyup.enter="confirmaEditarNombreAndBlur(device.docID, device.name)"
        :ref="'nameInputRef' + device.docID"
        @blur="confirmaEditarNombre(device.docID, device.name)" autocomplete="off">
        <!-- <img  @click="confirmaEditarNombre(device.docID,device.name)"
         :title="$t('devicesTable.editChargerName')"
         :alt="$t('devicesTabloeditChargerName')"
        src="/assets/img/ico-OK.svg" class="ico soloHover"> -->
    </div>

    <div>{{device.ident}}</div>

    <!-- <div :id="'meteDIV'+device.docID" class="meteDIV none">
      <img :id="'meteOKico'+device.docID"
           @click="confirmMeterEnGrupo(device.docID)"
           class="tr-ico ico-activo invisible" :title="$t('devicesTable.send')"
            :alt="$t('devicesTable.send')"
           src="/assets/img/ico-OK.svg">
    </div> -->

    <div :id="'deviceDIV'+device.docID">
      <img :src="device.chargerImgSrc" :alt="$t('devicesTable.deviceImage')" class="devicePic">
      &nbsp;{{device.type}}
    </div>

    <div :id="'icosDIV'+device.docID" class="icos">
      <!-- iconos -->
      <img v-if="vista==='Super'" @click="confirmaEliminarCargador(device.docID)"
        :title="$t('devicesTable.deleteCharger')" :alt="$t('devicesTable.deleteCharger')"
        src="/assets/img/ico-trash.svg" class="ico soloHover">
      <img v-if="vista==='Super'" :title="$t('devicesTable.addToGroup')"
        :alt="$t('devicesTable.addToGroup')"
        src="/assets/img/ico-meter.svg" class="ico soloHover"
          @click="meteEnUbicacionStart(device.docID)">
      <!-- /iconos -->
    </div>
  </div>
  <!-- /cargador fuera de ubicacion -->
</template>
<!-- /ITERATE OVER DEVICES WITHOUT LOCATION -->

  </div>

  </div><!-- /gridDatosContainer -->
  <!-- /CONTENEDOR DE TRS DE DATOS -->

  <!-- /NUEVA TABLA DE DATOS con grid -->
  <!-- ------------------------------ -->

  </div>
  <!-- /PANEL BLANCO CARGADORES -->
  <!-- ------------------------ -->
  </template>

<script>
import Modal from '@/components/Modal.vue';
import moment from 'moment';
import ModalUbicaciones from './ModalUbicaciones.vue';
import { db, dataPrefix } from '../includes/firebase';

export default {
  name: 'DevicesTable',

  components: { ModalUbicaciones, Modal },

  emits: ['device-selected', 'select-all', 'delete-group', 'add-group', 'add-pending-device',
    'send-reminder', 'remove-device-from-group', 'add-device-to-group', 'change-group-name',
    'add-new-group', 'delete-device', 'delete-pending-device', 'update-user-charger-name',
    'update-user-ubi-name', 'ubi-selected'],
  data() {
    return {
      selectedDevices: [],

      newChargerSN: '',
      newChargerSNMinLength: '5',
      newGroupName: '',
      newGroupNameMinLength: '3',
      newCharger: null,

      // JUANMA --------------------------
      // ---------------------------------
      modalData: {
        isOpen: false,
        iconSrc: '/assets/img/ico-warn.svg',
        title: '',
        text: [],
        confirmButtonClass: '',
        confirmButtonText: '',
        confirmAction: null,
        showCancelButton: null,
        cancelAction: null,
      },
      modalUbicacionesData: {
        isOpen: false,
        iconSrc: '/assets/img/ico-warn.svg',
        title: '',
        text: [],
        confirmButtonClass: '',
        confirmButtonText: '',
        confirmAction: null,
        showCancelButton: null,
        cancelAction: null,
      },
      ubicacionAutocompleteItems: this.locGroups.map((locGroup) => locGroup.name),
    };
  },
  props: {
    devices: {
      type: Array,
      required: true,
      default: () => [],
    },
    allDevices: {
      type: Array,
      required: true,
      default: () => [],
    },
    locGroups: {
      type: Array,
      required: true,
      default: () => [],
    },
    pendingDevices: {
      type: Array,
      required: true,
      default: () => [],
    },
    devicesWithoutLocation: {
      type: Array,
      required: true,
      default: () => [],
    },
    vista: {
      type: String,
      required: true,
      default: 'Super',
    },
  },
  computed: {
    locGroupsView() {
      // Añadir a los elementos de logGRoups la propiedad isCollapsed
      // y devolver el array
      return this.locGroups.map((locGroup) => ({
        ...locGroup,
        isCollapsed: true,
      }));
    },
    isAnyDeviceSelected() {
      return this.selectedDevices.length > 0;
    },
    areAllDevicesSelected() {
      return this.selectedDevices.length === this.devices.length;
    },

  },
  methods: {
    calculateInputWidth(content) {
      const maxLength = 20; // Máxima longitud permitida
      const contentLength = content.length;
      const adjustedLength = contentLength > maxLength ? maxLength : contentLength;

      // Puedes ajustar el valor según tus preferencias para el ancho del carácter
      const charWidth = 1;

      return adjustedLength * charWidth;
    },
    formatDate(timestamp) {
      return moment(timestamp).format('YYYY-MM-DD HH:mm');
    },
    abrirModalBuscando() {
      this.modalData = {
        isOpen: true,
        iconSrc: '/assets/img/ico-warn.svg',
        title: this.$t('devicesTable.searchingTitle'),
        text: [this.$t('devicesTable.searchingText')],
        confirmButtonClass: '',
        confirmButtonText: '',
        confirmAction: null,
        showCancelButton: null,
        cancelAction: null,
      };
    },
    buscarDevice(numSerie) {
      // quitar 3 primeros caracteres del numSerie
      const numSerieShort = numSerie.slice(3);

      // Abrir modal de búsqueda
      this.abrirModalBuscando(numSerie);

      return new Promise((resolve) => {
        // Obtener la referencia a la lista de dispositivos
        const devicesRef = db.ref(`/${dataPrefix}/devices`);

        // Variable para almacenar el dispositivo encontrado
        let foundDevice = null;

        // Escuchar el evento "value" en la referencia de dispositivos
        devicesRef.once('value', (snapshot) => {
          // Iterar sobre los hijos de la referencia (dispositivos)
          snapshot.forEach((childSnapshot) => {
            // Obtener la clave (key) del dispositivo
            const deviceKey = childSnapshot.key;
            // console.log('deviceKey:', deviceKey);
            // console.log('numSerie:', numSerieShort);

            // Verificar si la clave del dispositivo termina con numSerie menos un caerácter
            if (deviceKey.endsWith(numSerieShort)) {
              // Dispositivo encontrado, almacenar y salir del bucle
              foundDevice = childSnapshot.val();
              foundDevice.docID = deviceKey;

              return true; // Romper el bucle forEach
            }
            return false; // Continuar el bucle forEach
          });

          // Resolver la promesa con el resultado
          resolve(foundDevice);
        });
      });
    },
    onClick(e) {
      const currentValue = e.target.value;
      if (e.target.checked) {
        this.selectedDevices.push(currentValue);
      } else {
        this.selectedDevices = this.selectedDevices.filter((item) => item !== currentValue);
      }
      this.$emit('device-selected', e);
    },
    onClickUBI(e) {
      const currentValue = e.target.value;
      // quito el UBI
      const currentValueShort = currentValue.slice(3);

      if (e.target.checked) {
        const locGroup = this.locGroups.find((group) => group.id === Number(currentValueShort));
        locGroup.devices.forEach((item) => {
          this.selectedDevices.push(item);
        });
      } else {
        // Delete from selectedDevices all devices in the group
        const locGroup = this.locGroups.find((group) => group.id === Number(currentValueShort));
        // eslint-disable-next-line no-return-assign
        locGroup.devices.forEach(() => {
          this.selectedDevices = this.selectedDevices.filter((item) => item !== currentValue);
        });
      }
      this.$emit('ubi-selected', e);
    },
    onSelectAll(e) {
      if (e.target.checked) {
        this.selectedDevices = [];
        this.devices.forEach((item) => this.selectedDevices.push(item.docID));
        // add also devices grouped in locGroups
        this.locGroups.forEach((locGroup) => {
          if (locGroup.devices) locGroup.devices.forEach((item) => this.selectedDevices.push(item));
        });
      } else {
        this.selectedDevices = [];
      }
      this.$emit('select-all', e);
    },
    isSelected(d) {
      return this.selectedDevices.includes(d);
    },
    selectAll() {
      this.selectedDevices = [];
      this.devices.forEach((item) => this.selectedDevices.push(item.docID));
      this.$emit('select-all');
    },
    areAnyDevicesInGroupSelected(locGroup) {
      // get devices in group thar are selected (present in this.selectedDevices)
      if (locGroup) {
        if (locGroup.devices) {
          const selectedDevicesInGroup = locGroup.devices.filter(
            (device) => this.selectedDevices.includes(device),
          );
          // return true if all devices in group are selected
          return selectedDevicesInGroup.length === locGroup.devices.length;
        }
        return false;
      }
      return false;
    },
    confirmaEliminarUbicacion(group) {
      this.modalData = {
        isOpen: true,
        title: `${this.$t('devicesTable.confirmDeleteGroup.title')} ${group.name}`,
        text: [
          this.$t('devicesTable.confirmDeleteGroup.text'),
        ],
        confirmButtonClass: 'button-peligro',
        confirmButtonText: this.$t('devicesTable.confirmDeleteGroup.confirmButtonText'),
        showCancelButton: true,
        confirmAction: () => this.eliminarUbicacion(group),
        cancelAction: () => this.cancelarEliminarUbicacion(),
      };
    },

    eliminarUbicacion(group) {
      // Lógica para eliminar ubicación
      this.modalData.isOpen = false;
      // Otra lógica específica para eliminar ubicación
      console.log(group);
      // Eliminbar ubicación de la lista de ubicaciones
      this.$emit('delete-group', group);
    }, // eliminarUbicacion()

    cancelarEliminarUbicacion() {
      this.modalData.isOpen = false;
      // Otra lógica específica para cancelar eliminar ubicación
    },
    confirmaEliminarCargador(key, ident) {
      this.modalData = {
        isOpen: true,
        title: `${this.$t('devicesTable.confirmDeleteCharger.title')} ${ident}`,
        text: [
          this.$t('devicesTable.confirmDeleteCharger.text'),
        ],
        confirmButtonClass: 'button-peligro',
        confirmButtonText: this.$t('devicesTable.confirmDeleteCharger.confirmButtonText'),
        showCancelButton: true,
        confirmAction: () => this.eliminarCargador(key),
        cancelAction: () => this.cancelarEliminarCargador(),
      };
    },

    eliminarCargador(e) {
      // Lógica para eliminar el cargador
      this.modalData.isOpen = false;
      // Eliminar el cargador de la lista de cargadores del usuario en la base de datos
      this.$emit('delete-device', e);
    },
    cancelarEliminarCargador() {
      this.modalData.isOpen = false;
      // Otra lógica específica para cancelar eliminar el cargador
    },
    confirmaEliminarPendingCharger(pendingDevice) {
      this.modalData = {
        isOpen: true,
        title: `${this.$t('devicesTable.confirmDeleteCharger.title')} ${pendingDevice.ident}`,
        text: `<b>${this.$t('devicesTable.confirmDeleteCharger.text')}</b><br/><br/> `,
        confirmButtonClass: 'button-peligro',
        confirmButtonText: this.$t('devicesTable.confirmDeleteCharger.confirmButtonText'),
        showCancelButton: true,
        confirmAction: () => this.eliminarPendingCharger(pendingDevice),
        cancelAction: () => this.cancelarEliminarPendingCharger(),
      };
    },

    eliminarPendingCharger(pendingDevice) {
      // Lógica para eliminar el cargador
      this.modalData.isOpen = false;
      // Eliminar el cargador de la lista de cargadores del usuario en la base de datos
      this.$emit('delete-pending-device', pendingDevice);
    },
    cancelarEliminarPendingCharger() {
      this.modalData.isOpen = false;
      // Otra lógica específica para cancelar eliminar el cargador
    },
    confirmaAgregarCargador() {
      // buscar si this.newChargerSN está en this.allDevices (deberán coincidir todos los caracteres
      // de this.newChargerSN con los últimos caracteres de device.ident)

      let charger = this.allDevices.find((device) => device.ident.endsWith(this.newChargerSN));

      if (!charger) {
        charger = this.pendingDevices.find((device) => device.ident === this.newChargerSN);
      }

      if (charger) {
        this.modalData = {
          isOpen: true,
          title: this.$t('devicesTable.confirmAddCharger.alreadyExists.title'),
          text: this.$t('devicesTable.confirmAddCharger.alreadyExists.text'),
          confirmButtonClass: 'button',
          confirmButtonText: this.$t('devicesTable.confirmAddCharger.alreadyExists.confirmButtonText'),
          showCancelButton: false,
          confirmAction: this.cancelaAgregarCargador,
          cancelAction: this.cancelaAgregarCargador,
        };
      } else {
        this.newCharger = this.buscarDevice(this.newChargerSN);

        this.buscarDevice(this.newChargerSN)
          .then((result) => {
            if (result) {
              console.log('Encontrado:', result);
              this.newCharger = {
                ident: this.newChargerSN,
                name: this.newChargerSN,
                users: result.users,
                docID: result.docID,
              };
              this.modalData = {
                isOpen: true,
                title: this.$t('devicesTable.confirmAddCharger.toBeAdded.title'),
                text: this.$t('devicesTable.confirmAddCharger.toBeAdded.text'),
                confirmButtonClass: 'button',
                confirmButtonText: this.$t('devicesTable.confirmAddCharger.toBeAdded.confirmButtonText'),
                showCancelButton: true,
                cancelButtonText: this.$t('devicesTable.confirmAddCharger.toBeAdded.cancelButtonText'),
                confirmAction: this.AgregarCargadorPendiente,
                cancelAction: this.cancelaAgregarCargador,
              };
            } else {
              this.newCharger = null;
              this.modalData = {
                isOpen: true,
                title: this.$t('devicesTable.confirmAddCharger.notFound.title'),
                text: this.$t('devicesTable.confirmAddCharger.notFound.text'),
                confirmButtonClass: 'button',
                confirmButtonText: this.$t('devicesTable.confirmAddCharger.notFound.confirmButtonText'),
                showCancelButton: false,
                confirmAction: this.cancelaAgregarCargador,
                cancelAction: this.cancelaAgregarCargador,
              };
            }
          })
          .catch((error) => {
            console.error('Error:', error);
          });
      }
    },

    crearUbicacion() {
      // Lógica para crear ubicación
      this.modalData.isOpen = false;
      // Comprobar si ya existe la ubicación con ese nombre
      const locGroup = this.locGroups.find((group) => group.name === this.newGroupName);
      // Si ya existe mostrar mensaje de error
      if (locGroup) {
        this.modalData = {
          isOpen: true,
          title: this.$t('devicesTable.confirmAddGroup.alreadyExists.title'),
          text: [this.$t('devicesTable.confirmAddGroup.alreadyExists.text') + this.newGroupName],
          confirmButtonClass: 'button',
          confirmButtonText: this.$t('devicesTable.confirmAddGroup.alreadyExists.confirmButtonText'),
          showCancelButton: false,
          confirmAction: this.cancelaAgregarCargador,
          cancelAction: this.cancelaAgregarCargador,
        };
      } else {
        // Si no existe la ubicación entonces la creamos
        this.modalData = {
          isOpen: true,
          title: `${`${this.$t('devicesTable.confirmAddGroup.toBeAdded.title')}  ${this.newGroupName}`}?`,
          text: [this.$t('devicesTable.confirmAddGroup.toBeAdded.text')],
          confirmButtonClass: 'button',
          confirmButtonText: this.$t('devicesTable.confirmAddGroup.toBeAdded.confirmButtonText'),
          showCancelButton: true,
          confirmAction: this.AgregarUbicacion,
          cancelAction: this.cancelaAgregarCargador,
        };
      }
    }, // crearUbicacion()
    AgregarCargadorPendiente() {
      // Lógica para agregar cargador
      this.modalData.isOpen = false;
      // add time stamp to newCharger EPOCH milliseconds
      this.newCharger.timestamp = Date.now();
      this.$emit('add-pending-device', this.newCharger);
    },
    AgregarUbicacion() {
      // Lógica para agregar ubicación
      this.modalData.isOpen = false;
      // Otra lógica específica para agregar ubicación
      this.$emit('add-new-group', this.newGroupName);
    },
    confirmaEnviarRecordatorio() {
      this.modalData = {
        isOpen: true,
        title: this.$t('devicesTable.confirmSendReminder.title'),
        text: [this.$t('devicesTable.confirmSendReminder.text')],
        confirmButtonClass: 'button',
        confirmButtonText: this.$t('devicesTable.confirmSendReminder.confirmButtonText'),
        showCancelButton: true,
        confirmAction: this.enviarRecordatorio,
        cancelAction: this.cancelaEnviarRecordatorio,
      };
    },
    confirmaSacarDeUbicacion(deviceKey, group) {
      console.log(deviceKey);
      this.modalData = {
        isOpen: true,
        title: this.$t('devicesTable.confirmRemoveFromGroup.title') + this.getDeviceName(deviceKey),
        text: [this.$t('devicesTable.confirmRemoveFromGroup.text')],
        confirmButtonClass: 'button-peligro',
        confirmButtonText: this.$t('devicesTable.confirmRemoveFromGroup.confirmButtonText'),
        showCancelButton: true,
        confirmAction: () => this.SacarDeUbicacion(deviceKey, group),
        cancelAction: () => this.cancelaSacarDeUbicacion(),
      };
    },
    SacarDeUbicacion(deviceKey, locGroup) {
      // Lógica para sacar de ubicación
      this.modalData.isOpen = false;
      // Otra lógica específica para sacar de ubicación
      this.$emit('remove-device-from-group', deviceKey, locGroup);
    },

    cancelaSacarDeUbicacion() {
      this.modalData.isOpen = false;
      // Otra lógica específica para cancelar sacar de ubicación
    },
    cancelaEnviarRecordatorio() {
      this.modalData.isOpen = false;
      // Otra lógica específica para cancelar enviar recordatorio
    },
    cancelaAgregarCargador() {
      this.modalData.isOpen = false;
      // Otra lógica específica para cancelar agregar cargador
    },
    EnviarRecordato() {
      // Lógica para enviar recordatorio
      this.modalData.isOpen = false;
      // Otra lógica específica para enviar recordatorio
    },

    AgregarCargador() {
      // Lógica para agregar cargador
      this.modalData.isOpen = false;
      // Otra lógica específica para agregar cargador
      this.emit('add-charger', this.newChargerSN);
    },

    muestraAnadirMenu() {
      document.getElementById('menuAnadirIconoPlus').classList.add('none');
      document.getElementById('menuAnadirIconoLess').classList.remove('none');
      document.getElementById('menu-anadir').classList.remove('none');
      document.getElementById('menu-anadir').focus();
    }, // muestraAnadirMenu()

    ocultaAnadirMenu() {
      document.getElementById('menuAnadirIconoPlus').classList.remove('none');
      document.getElementById('menuAnadirIconoLess').classList.add('none');
      document.getElementById('menu-anadir').classList.add('none');

      document.getElementById('anadirCargador').classList.remove('active');
      document.getElementById('crearUbicacion').classList.remove('active');
      document.getElementById('anadirCargador').classList.remove('none');
      document.getElementById('crearUbicacion').classList.remove('none');

      document.getElementById('numeroDeSerie').classList.remove('none');
      document.getElementById('numeroDeSerie').classList.add('none');

      document.getElementById('ubicacion').classList.remove('none');
      document.getElementById('ubicacion').classList.add('none');
    }, // ocultaAnadirMenu()

    mostrarAnadirCargador() {
      document.getElementById('crearUbicacion').classList.toggle('none');
      document.getElementById('numeroDeSerie').classList.toggle('none');
      document.getElementById('anadirCargador').classList.toggle('active');
      this.newChargerSN = '';
      document.getElementById('inputSerialNumber').focus();
    }, // mostrarAnadirCargador()

    mostrarCrearUbicacion() {
      document.getElementById('anadirCargador').classList.toggle('none');
      document.getElementById('ubicacion').classList.toggle('none');
      document.getElementById('crearUbicacion').classList.toggle('active');
      this.newGroupName = '';
      document.getElementById('inputNombreUbicacion').focus();
    }, // mostrarCrearUbicacion()

    checkSerialNumberLength() {
      const actualLength = document.getElementById('inputSerialNumber').value.length;
      if (actualLength > this.newChargerSNMinLength) {
        document.getElementById('iconoEnviarSN').classList.remove('invisible');
      } else {
        document.getElementById('iconoEnviarSN').classList.add('invisible');
      }
    }, // checkSerialNumberLength()

    submitNumeroDeSerie() {
      const actualLength = document.getElementById('inputSerialNumber').value.length;
      if (actualLength > this.newChargerSNMinLength) {
        document.getElementById('modalAgregarCargador1').classList.toggle('none');
        document.getElementById('iconoEnviarSN').classList.toggle('invisible');
        // alert("Solicitud enviada");
        this.newChargerSN = '';
        this.mostrarAnadirCargador();
        this.ocultaAnadirMenu();
      }
    }, // submitNumeroDeSerie()

    checkcrearUbicacionLength() {
      const actualLength = document.getElementById('inputNombreUbicacion').value.length;
      if (actualLength > this.newGroupNameMinLength) {
        document.getElementById('iconoCrearUbicacion').classList.remove('invisible');
      } else {
        document.getElementById('iconoCrearUbicacion').classList.add('invisible');
      }
    }, // checkSerialNumberLength()

    desplegarUbicacion(x) {
      console.log(x);
      document.getElementById(x).classList.toggle('colapsada');
      document.getElementById(`${x}_icodesplegar`).classList.toggle('none');
      document.getElementById(`${x}_replegar`).classList.toggle('none');
    }, // desplegarUbicacion()

    meteEnUbicacionStart(deviceId) {
      // mostrar modal de ubicaciones
      console.log(deviceId);
      // si hay agrupaciones mostrar modal si no error
      if (this.locGroups.length === 0) {
        this.modalData = {
          isOpen: true,
          title: this.$t('devicesTable.confirmAddToGroup.noGroups.title'),
          text: this.$t('devicesTable.confirmAddToGroup.noGroups.text'),
          confirmButtonClass: 'button',
          confirmButtonText: this.$t('devicesTable.confirmAddToGroup.noGroups.confirmButtonText'),
          showCancelButton: false,
          confirmAction: this.cancelaMeteEnUbicacion,
          cancelAction: this.cancelaMeteEnUbicacion,
        };
      } else {
        this.modalUbicacionesData = {
          isOpen: true,
          title: this.$t('devicesTable.confirmAddToGroup.addToGroup.title'),
          text: this.$t('devicesTable.confirmAddToGroup.addToGroup.text'),
          confirmButtonClass: 'button',
          confirmButtonText: this.$t('devicesTable.confirmAddToGroup.addToGroup.confirmButtonText'),
          showCancelButton: true,
          confirmAction: (groupName) => this.meteEnUbicacion(deviceId, groupName),
          cancelAction: () => this.cancelaMeteEnUbicacion(),
        };
      }
    }, // meteEnUbicacionStart()
    meteEnUbicacion(deviceId, e) {
      console.log(deviceId, e.selectedLocation);
      // Lógica para meter en ubicación
      this.modalUbicacionesData.isOpen = false;
      // Otra lógica específica para meter en ubicación
      this.$emit('add-device-to-group', deviceId, e.selectedLocation);
    }, // meteEnUbicacion()
    cancelaMeteEnUbicacion() {
      this.modalUbicacionesData.isOpen = false;
      this.modalData.isOpen = false;
      // Otra lógica específica para cancelar meter en ubicación
    },
    meteEnUbicacionCheckLength(x) {
      // alert(x);
      if (document.getElementById(`meteINPUT${x}`).value.length >= 3) {
        document.getElementById(`meteOKico${x}`).classList.remove('invisible');
      }
    }, // meteEnUbicacionCheckLength()
    confirmaEditarNombreAndBlur(chargerDocID, deviceName) {
      this.confirmaEditarNombre(chargerDocID, deviceName);
      this.$refs[`nameInputRef${chargerDocID}`][0].blur();
    },
    confirmaEditarNombre(chargerDocID, deviceName) {
      this.modalData.isOpen = false;
      console.log(chargerDocID, deviceName);
      // Otra lógica específica para editar nombre
      this.$emit('update-user-charger-name', chargerDocID, deviceName);
    },
    confirmaEditarNombreUbiAndBlur(locId, locName) {
      this.confirmaEditarNombreUbi(locId, locName);
      this.$refs[`nameUbiInputRef${locId}`][0].blur();
    },
    confirmaEditarNombreUbi(locId, locName) {
      this.modalData.isOpen = false;
      console.log(locId, locName);
      // Otra lógica específica para editar nombre
      this.$emit('update-user-ubi-name', locId, locName);
    },

    confirmaEditarNombreAndBlur2(chargerDocID) {
      this.confirmaEditarNombre(chargerDocID);
      this.$refs[`nameInputRef${chargerDocID}`][0].blur();
      // update this.devices with the new name
    },
    confirmaEditarNombre2(chargerDocID) {
      this.modalData.isOpen = false;
      console.log(chargerDocID);
      const newName = this.$refs[`nameInputRef${chargerDocID}`][0].value;
      this.$emit('update-user-charger-name', chargerDocID, newName);

      // update in this.devices array the device with the new name
      const device = this.devices.find((dev) => dev.docID === chargerDocID);
      device.name = newName;
    },
    // Helper methods for dynamic content
    getDeviceName(docID) {
      return this.devices.find((device) => device.docID === docID)?.name || '';
    },
    getDeviceIdent(docID) {
      return this.devices.find((device) => device.docID === docID)?.ident || '';
    },
    getDeviceImgSrc(docID) {
      return this.devices.find((device) => device.docID === docID)?.chargerImgSrc || '';
    },
    getDeviceType(docID) {
      return this.devices.find((device) => device.docID === docID)?.type || '';
    },

  },
  mounted() {
    this.modalData = {
      isOpen: false,
      iconSrc: '/assets/img/ico-warn.svg',
      title: '',
      text: [],
      confirmButtonClass: '',
      confirmButtonText: '',
      confirmAction: null,
      cancelAction: null,
    };
  },
};

</script>
