<template>
  <!-- -------------------------------- -->
  <!-- ---------- Home.vue ------------ -->

  <!-- LENGUETAS -->
  <div class="blade-lenguetas">
  <div id="lenguetaAdmin" ref="lenguetaAdmin" v-if="showAdminTab" @click="cambiaVista('Admin')"
    :class="{ 'lenguetaOn': vista === 'Admin', 'lenguetaOff': vista !== 'Admin' }">
  {{$t('home.tabAdministrator').toUpperCase()}} ({{ devicesAdmin.length }})</div>
  <div id="lenguetaAutor" ref="lenguetaAutor" v-if="showAutorTab" @click="cambiaVista('Autor')"
    :class="{ 'lenguetaOn': vista === 'Autor', 'lenguetaOff': vista !== 'Autor' }">
    {{$t('home.tabAuthorized').toUpperCase()}} ({{ devicesAutor.length }})</div>
  <div id="lenguetaSuper" ref="lenguetaSuper" @click="cambiaVista('Super')"
    :class="{ 'lenguetaOn': vista === 'Super', 'lenguetaOff': vista !== 'Super' }">
  {{$t('home.tabSupervisor').toUpperCase()}} ({{ devicesSuper.length }})</div>
  <!-- DISABLED
  <div id="lenguetaAdmin" class="lenguetaDis">ADMINISTRADOR</div>
  <div id="lenguetaSuper" class="lenguetaDis">SUPERVISOR</div>
  <div id="lenguetaAutor" class="lenguetaDis">AUTORIZACIONES</div>
  -->
  </div>
  <!-- /LENGUETAS -->

  <!-- BLADE TOP  -->
  <div class="blade-top">
  <div class="columna-izq">

  <!-- TIMERANGE PANEL -->
  <div class="panel-timerange">
      <!-- TIMEFRAME -->
      <div class="timeframePanel">
<div><img src="assets/img/date.svg" class="iconoPanel"></div>

<div class="TFset">

    <!-- dates inputs -->
    <div class="TFsetRow1">
        <div class="fromTo">{{$t('home.dateFrom')}}</div>
        <div class="fromToField">
        <input type="date" id="fecha-inicio" class="date-input"
        v-model="filterDateFrom"
        @input.prevent="datesChanged" min="2000/01/01" :max="filterDateTo">
        </div>
        <div>&nbsp;</div>
        <div>&nbsp;</div>
        <div class="fromTo">{{$t('home.dateTo')}}</div>
        <div class="fromToField">
        <input type="date" id="fecha-final" class="date-input"
        v-model="filterDateTo"
        @input.prevent="activeFilter = 'None'"
        :min="filterDateFrom" :change="resetMaxEnergy">
        </div>
    </div>

    <!-- dates shortcuts -->
    <div class="TFsetRow2">

    <div :class="[isActive('Week') ? 'date-shortcuts-cell': 'date-shortcuts-cell-inactive']">
    <a :class="[isActive('Week') ? 'date-shortcuts-link-activo':'date-shortcuts-link']"
    href="#" @click="setFilterWeek">{{$t('home.7days')}}</a></div>
    <div :class="[isActive('Month') ? 'date-shortcuts-cell': 'date-shortcuts-cell-inactive']">
    <a :class="[isActive('Month') ? 'date-shortcuts-link-activo':'date-shortcuts-link']"
    href="#" @click="setFilterMonth">{{$t('home.30days')}}</a></div>
    <div :class="[isActive('Year') ? 'date-shortcuts-cell': 'date-shortcuts-cell-inactive']">
    <a :class="[isActive('Year') ? 'date-shortcuts-link-activo':'date-shortcuts-link']"
    href="#" @click="setFilterYear">{{$t('home.1year')}}</a></div>
    <!-- <div class="">&nbsp;|&nbsp;</div> -->
    <div :class="[isActive('ThisWeek') ? 'date-shortcuts-cell': 'date-shortcuts-cell-inactive']">
    <a :class="[isActive('ThisWeek') ? 'date-shortcuts-link-activo':'date-shortcuts-link']"
    href="#" @click="setFilterThisWeek">{{$t('home.thisWeek')}}</a>
    </div>
    <div :class="[isActive('ThisMonth') ? 'date-shortcuts-cell': 'date-shortcuts-cell-inactive']">
    <a :class="[isActive('ThisMonth') ? 'date-shortcuts-link-activo':'date-shortcuts-link']"
    href="#" @click="setFilterThisMonth">{{$t('home.thisMonth')}}</a></div>
    <div :class="[isActive('ThisYear') ? 'date-shortcuts-cell': 'date-shortcuts-cell-inactive']">
    <a :class="[isActive('ThisYear') ? 'date-shortcuts-link-activo':'date-shortcuts-link']"
    href="#" @click="setFilterThisYear">{{$t('home.thisYear')}}</a></div>

    </div>
    <!-- /dates shortcuts -->

</div>

      </div>
      <!-- TIMEFRAME -->
  </div>
  <!-- /TIMERANGE PANEL -->

  </div><!-- /columna-izq -->
  <div class="columna-top-der">

  <!-- TOTAL NUMBERS PANEL -->
  <div class="paneles-totales">
    <div class="panel-corto">
  <div class="total-titulo">kWh</div>
  <div class="total-valor" test-data="totalEnergy">
      {{this.filteredRecords.length>0 ? $n(totalEnergy, 'decimal'): '-'}}</div>
  </div>

  <div class="panel-corto">
  <div class="total-titulo">{{$t('home.chargeRecords')}}</div>
  <div class="total-valor" test-data="totalRecords">
      {{this.filteredRecords.length>0 ? this.filteredRecords.length: '-'}}</div>
  </div>

  <div class="panel-corto">
  <div class="total-titulo">{{$t('home.days')}}</div>
  <div class="total-valor" test-data="totalDays">
      {{totalDays}}</div>
  </div>
  </div>
  <!-- /TOTAL NUMBERS PANEL -->

  </div><!-- /columna-top-der -->

  </div>
  <!-- /BLADE TOP  -->

  <!-- MAIN CONTENT BLADE -->
  <div class="blade">

  <!-- COL1 -->
  <div class="columna-izq">
  <div class="panel-arriba">

    <devices-table ref="devicesTable" :devices="devicesVista"
      :allDevices="devices" :locGroups="locGroups"
      :pendingDevices="supervisionRequests" :devicesWithoutLocation="devicesWithoutLocation"
      :vista="vista"
      @device-selected="onSelDevice"
      @add-pending-device="onAddPendingDevice"
      @add-new-group="onAddGroup"
      @add-device-to-group="onAddDeviceToGroup"
      @remove-device-from-group="onRemoveDeviceFromGroup"
      @select-all="onSelectAllDevices"
      @delete-group="onDeleteGroup"
      @delete-device="onDeleteDevice"
      @delete-pending-device="onDeletePendingDevice"
      @update-user-charger-name="onUpdateUserChargerName"
      @update-user-ubi-name="onUpdateUserUbiName"
      @ubi-selected="onSelUbi"
      />
  </div>
  <div class="panel-abajo">

    <users-table ref="usersTable" :users="users"
      @user-selected="onSelUser"
      @select-all="onSelectAllUsers"/>

  </div>
  </div>
  <!-- /COL1 -->

  <!-- COL2 -->
  <div class="columna-der">
    <records-table ref="recordsTable"
      :records="filteredRecordsWithGroup"
      :devices="devices"
      :users="users"
      :selectedDevices="selectedDevices"
      :selectedUsers="selectedUsers"
      :dateFrom="filterDateFrom" :dateTo="filterDateTo"/>
  </div>
  <!-- /COL2 -->

  </div>
  <!-- /MAIN CONTENT BLADE -->

  <!-- ---------- /Home.vue ------------ -->
  <!-- --------------------------------- -->
  </template>

<script>
import '../assets/veltium.css';
import _ from 'lodash';
import moment from 'moment';
import NProgress from 'nprogress';
import DevicesTable from '@/components/DevicesTable.vue';
import UsersTable from '@/components/UsersTable.vue';
import RecordsTable from '@/components/RecordsTable.vue';
import Helper from '@/includes/helper';
import {
  db, auth, dataPrefix, functions,
} from '../includes/firebase';

export default {
  name: 'Home',
  components: { DevicesTable, UsersTable, RecordsTable },
  data() {
    return {
      devices: [],
      users: [],
      records: [],
      devUsrIdxs: [], // save for each device the user index and ID
      filterDateFrom: this.formatDate(new Date()),
      filterDateTo: this.formatDate(new Date()),
      userid: auth.currentUser.uid,
      // userid: 'yMTPW3hD2WPkJeYnwQBuR6AlHtg1',
      activeFilter: 'Month',
      selectedDevices: [],
      selectedUsers: [],
      userData: [],
      locGroups: [

      ], // Agrupaciones (localizaciones) de devices del usuario
      // pending devices
      supervisionRequests: [
      ],
      vista: '',
      devicesVista: [],
      // firebase sync collections
      supervisionRequestsRef: null,
    };
  },
  async beforeRouteEnter(to, from, next) {
    next();
  },
  async mounted() {
    await this.fetchData();
    if (auth.currentUser.uid) console.log('OK');
    const userLocale = this.$store.state.auth.userLogged.locale;
    if (process.env.VUE_APP_I18N_SUPPORTED_LOCALE.includes(userLocale)) {
      this.$i18n.locale = userLocale;
    }
    // this.vista = 'Super';
    // this.devicesVista = this.devicesSuper;
    const { userid } = this;
    // Crear la referencia a "supervisionRequests" del usuario
    this.supervisionRequestsRef = db.ref(`/${dataPrefix}/users/${userid}/supervisionRequests`);

    // comprobar si existe la referencia, si no existe, crearla
    this.supervisionRequestsRef.once('value', (snapshot) => {
      if (!snapshot.exists()) {
        console.log('No existe la referencia a "supervisionRequests" del usuario', userid);
      } else {
        // leer los datos de la referencia y actualizar el array supervisionRequests
        this.supervisionRequests = Helper.snapshotToArray(snapshot);
      }
    });
    // Mismo proceso para "locGroups"
    this.locGroupsRef = db.ref(`/${dataPrefix}/users/${userid}/locGroups`);

    // comprobar si existe la referencia, si no existe, crearla
    this.locGroupsRef.once('value', (snapshot) => {
      if (!snapshot.exists()) {
        console.log('No existe la referencia a "locGroups" del usuario', userid);
      } else {
        // leer los datos de la referencia y actualizar el array locGroups
        this.locGroups = Helper.snapshotToArray(snapshot);
      }
    });
  },
  computed: {
    filteredRecords() {
      let tmpRecords = this.records;
      tmpRecords = tmpRecords.filter((item) => {
        const isInUsers = this.selectedUsers.includes(item.userID);
        const isInDevices = this.selectedDevices.includes(item.deviceID);
        const inDateFilter = item.sDay >= this.filterDateFrom && item.sDay <= this.filterDateTo;
        return isInUsers && isInDevices && inDateFilter;
      });
      return _.orderBy(tmpRecords, 'con', 'desc');
    },
    filteredRecordsWithGroup() {
      return this.filteredRecords.map((record) => {
        // Buscar la agrupación correspondiente al deviceID en el array de deviceGroups
        const group = this.locGroups.find((g) => {
          if (g.devices) return g.devices.includes(record.deviceID);
          return false;
        });

        // Agregar la propiedad de agrupación al registro
        // eslint-disable-next-line no-param-reassign
        record.groupName = group ? group.name : '-';

        return record;
      });
    },
    totalEnergy() {
      return (_.sumBy(this.filteredRecords, 'energy')) / 1000;
    },
    totalDays() {
      return this.dateDifference(this.filterDateTo, this.filterDateFrom, 'days') + 1;
    },
    devicesWithoutLocation() {
      return this.devicesVista.filter(
        (device) => !this.isDeviceInGroup(device.docID),
      );
    },
    devicesAdmin() {
      const currentUserID = this.userid;

      // Buscar el usuario actual en devUsrIdxs (todas las apariciones) devolviendo el device ID
      const currentUserIndexes = this.devUsrIdxs.filter((item) => item.userID === currentUserID);

      // Obtener todos los deviceIDs asociados al usuario actual
      const currentUserDeviceIDs = currentUserIndexes.map((item) => item.deviceID);

      // Filtrar los dispositivos que están asociados a alguno de los deviceIDs del usuario actual
      const tmp2 = this.devices.filter((dev) => (
        currentUserDeviceIDs.includes(dev.docID)
    && currentUserIndexes.some((index) => index.deviceID === dev.docID && index.user_type === 0)
      ));

      return tmp2;
    },

    devicesSuper() {
      const currentUserID = this.userid;

      // Buscar el usuario actual en devUsrIdxs (todas las apariciones) devolviendo el device ID
      const currentUserIndexes = this.devUsrIdxs.filter((item) => item.userID === currentUserID);

      // Obtener todos los deviceIDs asociados al usuario actual
      const currentUserDeviceIDs = currentUserIndexes.map((item) => item.deviceID);

      // Filtrar los dispositivos que están asociados a alguno de los deviceIDs del usuario actual
      const tmp2 = this.devices.filter((dev) => (
        currentUserDeviceIDs.includes(dev.docID)
    && currentUserIndexes.some((index) => index.deviceID === dev.docID && index.user_type === 2)
      ));

      return tmp2;
    },

    devicesAutor() {
      const currentUserID = this.userid;

      // Buscar el usuario actual en devUsrIdxs (todas las apariciones) devolviendo el device ID
      const currentUserIndexes = this.devUsrIdxs.filter((item) => item.userID === currentUserID);

      // Obtener todos los deviceIDs asociados al usuario actual
      const currentUserDeviceIDs = currentUserIndexes.map((item) => item.deviceID);

      // Filtrar los dispositivos que están asociados a alguno de los deviceIDs del usuario actual
      const tmp2 = this.devices.filter((dev) => (
        currentUserDeviceIDs.includes(dev.docID)
    && currentUserIndexes.some((index) => index.deviceID === dev.docID && index.user_type === 1)
      ));

      return tmp2;
    },
    showAdminTab() {
      return this.devicesAdmin.length > 0;
    },
    showAutorTab() {
      return this.devicesAutor.length > 0;
    },

  },
  methods: {
    vistaPorDefecto() {
      if (this.devicesSuper.length > 0) {
        // simular click en lengueta Super
        this.$refs.lenguetaSuper.click();
      } else if (this.devicesAdmin.length > 0) {
        // simular click en lengueta Admin
        this.$refs.lenguetaAdmin.click();
      } else if (this.devicesAutor.length > 0) {
        // simular click en lengueta Autor
        this.$refs.lenguetaAutor.click();
      } else {
        // simular click en lengueta Super
        this.$refs.lenguetaSuper.click();
      }
    },
    // Functions to update firebase supervisionRequests and locGroups
    updatePendingDevicesOnFirebase(device) {
      // add device to supervisionRequests
      if (device) console.log('updatePendingDevicesOnFirebase');
      // add to firebase
      this.supervisionRequestsRef.set(this.supervisionRequests);
    },
    updateLocGroupsOnFirebase() {
      // add locGroups to firebase
      // add to firebase
      this.locGroupsRef.set(this.locGroups);
    },

    cambiaVista(vista) {
      if (vista === 'Admin') { this.devicesVista = this.devicesAdmin; }
      if (vista === 'Super') { this.devicesVista = this.devicesSuper; }
      if (vista === 'Autor') { this.devicesVista = this.devicesAutor; }
      this.vista = vista;
      this.$refs.devicesTable.selectedDevices = [];
      this.$refs.usersTable.selectedUsers = [];
      this.selectedDevices = [];
      this.selectedUsers = [];
    },
    isDeviceInGroup(docID) {
      return this.locGroups.some(
        (locGroup) => locGroup.devices && locGroup.devices.includes(docID),
      );
    },
    onSelDevice(e) {
      const currentValue = e.target.value;
      if (e.target.checked) {
        this.selectedDevices.push(currentValue);
      } else {
        this.selectedDevices = this.selectedDevices.filter((item) => item !== currentValue);
      }
    },
    onSelUbi(e) {
      const currentValue = e.target.value;
      // quito el UBIGR
      const currentValueShort = currentValue.slice(3);
      if (e.target.checked) {
        const locGroup = this.locGroups.find((group) => group.id === Number(currentValueShort));
        locGroup.devices.forEach((item) => {
          this.selectedDevices.push(item);
        });
      } else {
        // Delete from selectedDevices all devices in the group
        const locGroup = this.locGroups.find((group) => group.id === Number(currentValueShort));
        // eslint-disable-next-line no-return-assign
        locGroup.devices.forEach((device) => {
          this.selectedDevices = this.selectedDevices.filter((item) => (item !== device));
        });
      }
    },
    onSelUser(e) {
      const currentValue = e.target.value;
      if (e.target.checked) {
        this.selectedUsers.push(currentValue);
      } else {
        this.selectedUsers = this.selectedUsers.filter((item) => item !== currentValue);
      }
    },
    onSelectAllDevices(e) {
      if (e.target.checked) {
        this.selectedDevices = [];
        this.devicesVista.forEach((item) => this.selectedDevices.push(item.docID));
      } else {
        this.selectedDevices = [];
      }
    },
    onSelectAllUsers(e) {
      if (e.target.checked) {
        this.selectedUsers = [];
        this.users.forEach((item) => this.selectedUsers.push(item.docID));
      } else {
        this.selectedUsers = [];
      }
    },
    onDeleteGroup(group) {
      // delete group from locGroups
      const index = this.locGroups.findIndex((g) => g.id === group.id);
      if (index !== -1) {
        this.locGroups.splice(index, 1);
      }
      // del from firebase
      this.updateLocGroupsOnFirebase();
    },
    onAddPendingDevice(device) {
      // add device to devices
      this.supervisionRequests.push(device);

      // add to firebase
      this.updatePendingDevicesOnFirebase(device);
    },
    onRemovePendingDevice(device) {
      // remove device from supervisionRequests
      const index = this.supervisionRequests.findIndex((d) => d.key === device.key);
      if (index !== -1) {
        this.supervisionRequests.splice(index, 1);
      }
      // del from firebase
      this.removePendingDeviceFromFirebase(device);
    },
    onRemoveDeviceFromGroup(deviceKey, locGroup) {
      // remove device from locGroup
      const index = this.locGroups.findIndex((g) => g.id === locGroup.id);
      if (index !== -1) {
        const deviceIndex = this.locGroups[index].devices.findIndex((d) => d === deviceKey);
        if (deviceIndex !== -1) {
          this.locGroups[index].devices.splice(deviceIndex, 1);
        }
      }
      // del from firebase
      this.updateLocGroupsOnFirebase();
    },
    onAddGroup(groupName) {
      // add group to locGroups
      // assign ID (max + 1) or Zero
      const maxId = this.locGroups.reduce((max, item) => (item.id > max ? item.id : max), 0);
      const group = {
        id: maxId + 1,
        name: groupName,
      };
      this.locGroups.push(group);
      // add to firebase
      this.updateLocGroupsOnFirebase();
    },
    onAddDeviceToGroup(deviceId, groupName) {
      // add device to locGroup
      const index = this.locGroups.findIndex((g) => g.name === groupName);
      if (index !== -1) {
        // if locGroups[index] has devices array, push deviceId
        if (this.locGroups[index].devices) {
          this.locGroups[index].devices.push(deviceId);
        } else {
          // if locGroups[index] has no devices array, create it and push deviceId
          this.locGroups[index].devices = [deviceId];
        }
      }
      // add to firebase
      this.updateLocGroupsOnFirebase();
    },
    onDeleteDevice(device) {
      // delete device from devices
      const index = this.devices.findIndex((d) => d.docID === device);
      if (index !== -1) {
        this.devices.splice(index, 1);
      }
      // delete from locGroups if present
      this.locGroups.forEach((locGroup) => {
        if (locGroup.devices) {
          const deviceIndex = locGroup.devices.findIndex((d) => d === device);
          if (deviceIndex !== -1) {
            locGroup.devices.splice(deviceIndex, 1);
          }
        }
      });
      // del from firebase
      this.removeDeviceFromFirebase(device);
    },
    async removeDeviceFromFirebase(device) {
      // remove device from firebase
      const { userid } = this;
      const ref = db.ref(`/${dataPrefix}/users/${userid}/devices/${device}`);
      await ref.remove();

      // remove user on devices
      const ref2 = db.ref(`/${dataPrefix}/devices/${device}/users/${userid}`);
      await ref2.remove();
    },
    onDeletePendingDevice(device) {
      // delete device from supervisionRequests
      const index = this.supervisionRequests.findIndex((d) => d.key === device.key);
      if (index !== -1) {
        this.supervisionRequests.splice(index, 1);
      }
      // del from firebase
      this.removePendingDeviceFromFirebase(device);
    },
    async removePendingDeviceFromFirebase(device) {
      // remove device from firebase
      const { userid } = this;
      const ref = db.ref(`/${dataPrefix}/users/${userid}/supervisionRequests/${device.key}`);
      await ref.remove();
    },
    async onUpdateUserChargerName(docID, chargerName) {
      // update user charger name
      const { userid } = this;
      const ref = db.ref(`/${dataPrefix}/users/${userid}/devices/${docID}/name`);
      await ref.set(chargerName);
    },
    async onUpdateUserUbiName(locID, ubiName) {
      // update user ubi name
      // get locID index from locGroups
      const index = this.locGroups.findIndex((g) => g.id === locID);
      const { userid } = this;
      const ref = db.ref(`/${dataPrefix}/users/${userid}/locGroups/${index}/name`);
      await ref.set(ubiName);
    },

    async fetchData() {
      const { userid } = this;

      const refdevs = db.ref(`/${dataPrefix}/users/${userid}/devices`);
      const dataSnapshot1 = await refdevs.get();
      const devs = Helper.snapshotToArray(dataSnapshot1);

      NProgress.start();
      await Promise.all(devs.map(async (dev) => {
        if (dev.enabled === 'true' || dev.enabled === true) { // FIX Issue-6: Añadir sólo si enabled es true.
          // por seguridad se acepta valor string y boolean
          await this.addDevice(dev);
        }
      }));

      await Promise.all(this.devices.map(async (dev) => {
        await this.addUsers(dev);
      }));

      await Promise.all(this.users.map(async (user) => {
        await this.addUserData(user);
      }));

      this.users = this.userData;
      this.usersData = [];

      this.addNameToDevIdx();

      await Promise.all(this.devices.map(async (dev) => {
        const loggedUserData = this.getUserByUserID(dev.docID, this.userid);
        if (loggedUserData) {
          await this.addDeviceRecords(dev, loggedUserData.user_type);
        }
      }));

      if (this.devices.length === 1) {
        this.$refs.devicesTable.$refs.checkAll.click();
      }

      if (this.users.length === 1) {
        this.$refs.usersTable.$refs.checkAll.click();
      }
      this.setFilterMonth();
      this.vistaPorDefecto();
      NProgress.done();
    },
    async addDevice(document) {
      const device = {
        ...document,
        docID: document.key,
      };
      let partNumber;
      let ident;
      // get device part_number
      const reference0 = db.ref(`/${dataPrefix}/devices/${document.key}/part_number`);
      try {
        const dataSnapshot0 = await reference0.get();
        partNumber = dataSnapshot0.exists() ? dataSnapshot0.val() : '0000000000';
        ident = `VCD${document.key.substr(20, 8)}`;
      } catch (error) {
        partNumber = '000000000';
        ident = '00000000000';
      }

      const deviceData = {
        ...device,
        partNumber: partNumber || '-',
        ident: ident || '',
        type: Helper.getDeviceType(partNumber),
        chargerImgSrc: `assets/img/${Helper.getImageName(partNumber)}.png`,
      };

      this.devices.push(deviceData);
    },
    async addUsers(device) {
      // get users from device
      const reference2 = db.ref(`/${dataPrefix}/devices/${device.key}/users`);
      const dataSnapshot2 = await reference2.get();

      const usrs = Helper.snapshotToArray(dataSnapshot2);

      // get the current logged user type on device
      const i = usrs.find(
        (o) => (o.key === this.userid),
      );

      let loggedUserType = 1; // by default: usuario supervisor
      if (i) {
        loggedUserType = i.user_type ? i.user_type : -1;
      }

      // eslint-disable-next-line no-restricted-syntax
      for (const usr of usrs) {
        if (loggedUserType !== 1
          || (loggedUserType === 1 && usr.key === this.userid)) {
        // eslint-disable-next-line no-await-in-loop
          await this.addUser(usr, device.docID);
        }
      }
      // TODO: comparar con lo de arriba y revisar si está bien
      // eslint-disable-next-line no-restricted-syntax
      // for (const usr of usrs) {
      //   if (loggedUserType !== 1
      //     || (loggedUserType === 1)) {
      //   // eslint-disable-next-line no-await-in-loop
      //     await this.addUser(usr, device.docID);
      //   }
      // }
    },
    async addUser(document, deviceID) {
      const user = {
        ...document,
        docID: document.key,
        deviceID,
      };
      const myUser = user;

      if (!this.users.find((o) => o.docID === document.key) && (document.key.length === 28)) {
        this.users.push(myUser);
      }
      if ((document.key.length === 28)) {
        this.devUsrIdxs.push({
          deviceID,
          userID: document.key,
          user_index: user.user_index,
          user_name: `${user ? myUser.name : `user${user.user_index}`}`,
          user_type: user.user_type,
        });
      }
    },
    async addUserData(user) {
      const existingUserIndex = this.userData.findIndex(
        (existingUser) => existingUser.docID === user.docID,
      );

      if (existingUserIndex !== -1) {
        // User already exists in the userData array, no need to add it again
        return;
      }

      const dataFields = ['name_cy', 'surname_cy', 'email_cy'];

      const userValues = await Promise.all(dataFields.map(async (field) => {
        const ref = db.ref(`/${dataPrefix}/users/${user.docID}/data/${field}`);
        const snapshot = await ref.get();

        return snapshot.exists() ? snapshot.val() : null;
      }));

      const requestData = {
        user_id: this.userid,
        user_values: userValues.filter((value) => value !== null),
      };

      try {
        const decryptUserData = functions.httpsCallable('decryptUserData');
        const result = await decryptUserData(requestData);
        const { data } = result;
        // last 4 chaaracters of the user id
        const last4 = user.docID.substr(user.docID.length - 4);

        const userData = {
          ...user,
          name: data.user_values[0] || (`${last4}`),
          surname: data.user_values[1] || (`${last4}`),
          email: data.user_values[2] || (`${last4}@${last4}.${last4}`),
        };

        this.userData.push(userData);
      } catch (error) {
        console.error('Error decrypting user data:', error);

        const last4 = user.docID.substr(user.docID.length - 4);

        const userData = {
          ...user,
          name: `${last4}`,
          surname: `${last4}`,
          email: `${last4}@${last4}.${last4}`,
        };
        this.userData.push(userData);
      }
    },
    addNameToDevIdx() {
      let tmp = [];
      this.devUsrIdxs.forEach((ud) => {
        const ud2 = ud;
        ud2.user_name = this.getUserName(ud2.userID);
        tmp.push(ud2);
      });
      this.devUsrIdxs = tmp;
      tmp = [];
    },
    addDeviceRecords(device, loggedUserType) {
      const reference3 = db.ref(`/${dataPrefix}/devices/${device.key}/records`);
      reference3.get().then((dataSnapshot3) => {
        dataSnapshot3.forEach((element) => {
          this.addRecord(element, device.docID,
            device.name, device.ident, loggedUserType); // pass down the reference to the device
        });
      });
    },
    addRecord(document, deviceID, deviceName, ident, loggedUserType) {
      // search the user index in the users associated to the device
      let userData = this.getUser(deviceID, document.val().usr);
      let recordDB = null;
      if (userData) {
        recordDB = {
          ...document.val(),
          docID: document.key,
          deviceID,
          deviceName,
          ident,
          userID: userData.userID ? userData.userID : 'not found',
          userName: userData.user_name ? userData.user_name : 'not found',
        };
      } else {
        // The user index associated to the record was not found in
        //     the user list associated to the device
        // The record will be associated to the device administrator
        userData = this.getAdminUser(deviceID);
        if (userData) {
          console.log('Record associated to the device administrator: ', document.key, userData);
          recordDB = {
            act: document.val().act,
            con: document.val().con,
            dis: document.val().dis,
            usr: userData.user_index,
            docID: document.key,
            deviceID,
            deviceName,
            ident,
            userID: userData.userID ? userData.userID : 'not found',
            userName: userData.user_name ? userData.user_name : 'not found',
          };
        } else {
          // The record does not belong to the user or to any device
          //     on which the user has administrator permissions
          // The record is ignored
          console.log('Record ignored: ', document.key);
          return;
        }
      }

      const record = Helper.formatCDR(recordDB, this.$i18n.locale);
      if (loggedUserType !== 1
          || (loggedUserType === 1 && userData.userID === this.userid)) {
        this.records.push(record);
      }
      // // TODO: comparar con lo de arriba y revisar si está bien
      // if (loggedUserType !== 1
      //     || (loggedUserType === 1)) {
      //   this.records.push(record);
      // }
    },
    getUser(deviceID, userIdx) {
      const i = this.devUsrIdxs.find(
        (o) => (o.deviceID === deviceID && o.user_index === userIdx),
      );

      return i;
    },
    getAdminUser(deviceID) {
      const i = this.devUsrIdxs.find(
        (o) => (o.deviceID === deviceID && o.user_type === 0),
      );

      return i;
    },
    getUserByUserID(deviceID, userID) {
      const i = this.devUsrIdxs.find(
        (o) => (o.deviceID === deviceID && o.userID === userID),
      );
      return i;
    },
    getUserName(userID) {
      const i = this.users.find(
        (o) => (o.docID === userID),
      );
      return `${i.name} ${i.surname}`;
    },
    setFilterWeek() {
      this.activeFilter = 'Week';
      const today = new Date();
      this.filterDateTo = this.formatDate(today);
      this.filterDateFrom = this.formatDate(today.setDate(today.getDate() - 7));
      this.$refs.recordsTable.setGraphPerDay();
    },
    setFilterMonth() {
      this.activeFilter = 'Month';
      const today = new Date();
      this.filterDateTo = this.formatDate(today);
      this.filterDateFrom = this.formatDate(today.setMonth(today.getMonth() - 1));
      this.$refs.recordsTable.setGraphPerDay();
    },
    setFilterYear() {
      this.activeFilter = 'Year';
      const today = new Date();
      this.filterDateTo = this.formatDate(today);
      this.filterDateFrom = this.formatDate(today.setYear(today.getFullYear() - 1));
      this.$refs.recordsTable.setGraphPerMonth();
    },
    setFilterThisWeek() {
      this.activeFilter = 'ThisWeek';
      const today = new Date();
      this.filterDateTo = this.formatDate(today);
      const firstDayWeek = new Date(today.setDate(today.getDate() - today.getDay()));
      this.filterDateFrom = this.formatDate(firstDayWeek);
      this.$refs.recordsTable.setGraphPerDay();
    },
    setFilterThisMonth() {
      this.activeFilter = 'ThisMonth';
      const today = new Date();
      this.filterDateTo = this.formatDate(today);
      const firstDayMonth = new Date(today.getFullYear(), today.getMonth(), 1);
      this.filterDateFrom = this.formatDate(firstDayMonth);
      this.$refs.recordsTable.setGraphPerDay();
    },
    setFilterThisYear() {
      this.activeFilter = 'ThisYear';
      const today = new Date();
      const firstDayYear = new Date(today.getFullYear(), 0, 1);
      this.filterDateTo = this.formatDate(today);
      this.filterDateFrom = this.formatDate(firstDayYear);
      this.$refs.recordsTable.setGraphPerMonth();
    },
    dateDifference(d2, d1) {
      const date2 = new Date(d2);
      const date1 = new Date(d1);
      const miliSecondsDay = 1000 * 60 * 60 * 24;
      // Discard the time and time-zone information.
      // get the miliseconds for each date provided
      const utc1 = Date.UTC(date1.getFullYear(), date1.getMonth(), date1.getDate());
      const utc2 = Date.UTC(date2.getFullYear(), date2.getMonth(), date2.getDate());
      // substract and dive per miliseconds of a day
      return Math.floor((utc2 - utc1) / miliSecondsDay) - 1;
    },
    formatDate(date) {
      return moment(date).format('YYYY-MM-DD');
    },
    isActive(filter) {
      return (filter === this.activeFilter);
    },
    resetMaxEnergy() {
      this.$refs.recordsTable.data.maxEnergy = 0;
    },
    datesChanged() {
      this.activeFilter = 'None';
    },

  },

};

</script>
<style scoped>

</style>
