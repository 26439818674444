export default {
  "devicesTable": {
    "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carregadores"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo"])},
    "site": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupo"])},
    "createSite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criar grupo"])},
    "addCharger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar carregador"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar..."])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar"])},
    "serialNumberPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de Série..."])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar"])},
    "createGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criar um Grupo"])},
    "groupNamePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome do Grupo..."])},
    "undoGrouping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desfazer Agrupamento"])},
    "expand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expandir"])},
    "deleteCharger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excluir Carregador"])},
    "removeFromGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remover do Grupo"])},
    "deviceImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imagem do Dispositivo"])},
    "requested": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solicitado"])},
    "remindApproval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lembrar Aprovação"])},
    "addToGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar ao Grupo"])},
    "confirmDeleteCharger": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excluir acesso ao carregador"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b>Você perderá o acesso ao carregador</b>!<br/><br/>Para recuperá-lo, será necessário solicitar a adição novamente."])},
      "confirmButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excluir"])}
    },
    "confirmDeleteGroup": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excluir agrupamento"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Os carregadores agora aparecem fora do agrupamento."])},
      "confirmButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excluir"])}
    },
    "confirmAddCharger": {
      "alreadyExists": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carregador já existe"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Você já tem um carregador com o número de série na sua lista."])},
        "confirmButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aceitar"])}
      },
      "toBeAdded": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar carregador?"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uma solicitação para adicionar o carregador será enviada. O carregador ficará pendente até que a solicitação seja aprovada."])},
        "confirmButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmar"])},
        "cancelButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar"])}
      },
      "notFound": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carregador não encontrado"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O número de série não foi encontrado no banco de dados."])},
        "confirmButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aceitar"])}
      }
    },
    "confirmAddGroup": {
      "alreadyExists": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupo já existente"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Você já possui um grupo na sua lista com esse nome."])},
        "confirmButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aceitar"])}
      },
      "toBeAdded": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar agrupação "])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Será criada a nova agrupação."])},
        "confirmButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmar"])},
        "cancelButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar"])}
      }
    },
    "confirmSendReminder": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lembrar Aprovação"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Um lembrete será enviado para aprovar o acesso ao carregador."])},
      "confirmButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmar"])},
      "cancelButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar"])}
    },
    "confirmRemoveFromGroup": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remover do Grupo. Carregador: "])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O carregador aparecerá fora do grupo."])},
      "confirmButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmar"])},
      "cancelButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar"])}
    },
    "confirmAddToGroup": {
      "noGroups": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhum grupo criado."])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use o botão + para criar um novo grupo."])},
        "confirmButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aceitar"])},
        "cancelButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar"])}
      },
      "addToGroup": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar ao Grupo"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O carregador aparecerá dentro do grupo."])},
        "confirmButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmar"])},
        "cancelButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar"])}
      }
    },
    "searchingTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Procurando..."])},
    "searchingText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, aguarde enquanto procuramos o carregador."])}
  },
  "footer": {
    "legal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aviso Legal"])},
    "privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Política de privacidade"])}
  },
  "header": {
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Encerrar sessão"])}
  },
  "home": {
    "1year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 ano"])},
    "30days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["30 dias"])},
    "7days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7 dias"])},
    "chargeRecords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recargas"])},
    "dateFrom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desde:"])},
    "dateTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Até:"])},
    "days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dias"])},
    "thisMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este mês"])},
    "thisWeek": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esta semana"])},
    "thisYear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este ano"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard LITE"])},
    "tabAuthorized": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autorizado"])},
    "tabSupervisor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supervisor"])},
    "tabAdministrator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrador"])}
  },
  "login": {
    "access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aceder"])},
    "enterMail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduza o seu email"])},
    "forgotPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esqueci a minha palavra-passe"])},
    "invalidLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Credenciais incorretas"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Palavra-passe"])},
    "rememberMe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lembrar-me"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login correto!"])},
    "wait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, espere enquanto comprovamos os dados."])}
  },
  "recordsTable": {
    "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exportar"])},
    "exportColumnCharger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carregador"])},
    "exportColumnDeviceId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
    "exportColumnEnd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fim"])},
    "exportColumnEnergy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Energia"])},
    "exportColumnStart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Início"])},
    "exportColumnUserName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilizador"])},
    "exportColumnGroupName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupo"])},
    "filterPerDay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por dia"])},
    "filterPerMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por mês"])},
    "tabDevices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CARREGADORES"])},
    "tabDevicesEnergy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Energia"])},
    "tabDevicesId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
    "tabDevicesName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
    "tabRecords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RECARGAS"])},
    "tabRecordsCharger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carregador"])},
    "tabRecordsEnd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fim"])},
    "tabRecordsEnergy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Energia"])},
    "tabRecordsStart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Início"])},
    "tabUsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UTILIZADORES"])},
    "tabUsersEnergy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Energia"])},
    "tabUsersName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recargas e consumos"])},
    "sheetTitle0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geral"])},
    "sheetTitle1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registros"])},
    "sheetTitle2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por carregador"])},
    "sheetTitle3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por usuário"])},
    "sheetTitle4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por grupo"])},
    "sheetGeneralDateFrom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De :"])},
    "sheetGeneralDateTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Até :"])},
    "sheetGeneralTotalRecords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total de registros :"])},
    "sheetGeneralTotalEnergy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total de energia (KwH) :"])},
    "sheetChargersTableSerialNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de série"])},
    "sheetChargersTableName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
    "sheetChargersTableType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo"])},
    "sheetUsersTableName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
    "sheetUsersTableEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])}
  },
  "reset": {
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voltar"])},
    "checkInbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verifique o seu correio"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuar"])},
    "enterMail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduza o seu email"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviámos-lhe instruções para restaurar a sua palavra-passe."])},
    "wait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, espere enquanto comprovamos os dados."])}
  },
  "usersTable": {
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilizadores"])}
  },
  "modal": {
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar"])}
  }
}