import { createRouter, createWebHistory } from 'vue-router';
import store from '@/store';
import Home from '../views/Home.vue';
import Login from '../views/Login.vue';
import Reset from '../views/Reset.vue';
import Register from '../views/Register.vue';

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    beforeEnter: (to, from, next) => {
      if (!store.state.auth.userLoggedIn) {
        next();
      } else {
        // user already logged in
        next({ name: 'home' });
      }
    },
  },
  {
    path: '/reset',
    name: 'reset',
    component: Reset,
  },
  {
    path: '/register',
    name: 'register',
    component: Register,
  },
  {
    path: '/:catchAll(.*)*',
    redirect: { name: 'home' },

  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (!to.matched.some((record) => record.meta.requiresAuth)) {
    next();
    return;
  }

  if (store.state.auth.userLoggedIn) {
    next();
  } else {
    next({ name: 'login' });
  }
});

export default router;
