export default {
  "devicesTable": {
    "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chargers"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
    "site": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group"])},
    "createSite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create group"])},
    "addCharger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add charger"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add..."])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "serialNumberPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serial Number..."])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])},
    "createGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Group"])},
    "groupNamePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group Name..."])},
    "undoGrouping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Undo Grouping"])},
    "expand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expand"])},
    "deleteCharger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete Charger"])},
    "removeFromGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove from Group"])},
    "deviceImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Device Image"])},
    "requested": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Requested"])},
    "remindApproval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remind Approval"])},
    "addToGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add to Group"])},
    "confirmDeleteCharger": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete access to Charger "])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b>You will lose access to the charger </b>!<br/><br/>To recover it, you will need to request to be added again."])},
      "confirmButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])}
    },
    "confirmDeleteGroup": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete Group "])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chargers will apear as ungrouped."])},
      "confirmButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])}
    },
    "confirmAddCharger": {
      "alreadyExists": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Charger already exists"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You already have a charger in your list with the serial number."])},
        "confirmButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accept"])}
      },
      "toBeAdded": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Charger?"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A request to add the charger will be sent."])},
        "confirmButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
        "cancelButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])}
      },
      "notFound": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Charger not found"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The serial number was not found in the database."])},
        "confirmButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accept"])}
      }
    },
    "confirmAddGroup": {
      "alreadyExists": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group already exists"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You already have a group in your list with the name."])},
        "confirmButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accept"])}
      },
      "toBeAdded": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Group"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The new group will be added to your list."])},
        "confirmButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
        "cancelButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])}
      }
    },
    "confirmSendReminder": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approve Reminder"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A reminder will be sent to approve access to the charger."])},
      "confirmButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
      "cancelButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])}
    },
    "confirmRemoveFromGroup": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove from Group. Charger: "])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The charger will appear outside of the group."])},
      "confirmButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
      "cancelButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])}
    },
    "confirmAddToGroup": {
      "noGroups": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No groups created."])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use the + button to create a new group."])},
        "confirmButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accept"])},
        "cancelButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])}
      },
      "addToGroup": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add to Group"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The charger will appear within the group."])},
        "confirmButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
        "cancelButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])}
      }
    },
    "searchingTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Searching..."])},
    "searchingText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please wait while we search for your charger..."])}
  },
  "footer": {
    "legal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legal Notice"])},
    "privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Policy"])}
  },
  "header": {
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close sesion"])}
  },
  "home": {
    "1year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 year"])},
    "30days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["30 days"])},
    "7days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7 days"])},
    "chargeRecords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Records"])},
    "dateFrom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From:"])},
    "dateTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To:"])},
    "days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Days"])},
    "thisMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This month"])},
    "thisWeek": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This week"])},
    "thisYear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This year"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard LITE"])},
    "tabAuthorized": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AUTHORIZED"])},
    "tabSupervisor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SUPERVISOR"])},
    "tabAdministrator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ADMINISTRATOR"])}
  },
  "login": {
    "access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access"])},
    "enterMail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter mail"])},
    "forgotPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I forgot my password"])},
    "invalidLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid credentials!"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "rememberMe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remember me"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success! You have been logged in."])},
    "wait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please wait! We are logging you in."])},
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register"])}
  },
  "recordsTable": {
    "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export"])},
    "exportColumnCharger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Charger"])},
    "exportColumnDeviceId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
    "exportColumnEnd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End"])},
    "exportColumnEnergy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Energy (Wh)"])},
    "exportColumnStart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start"])},
    "exportColumnUserName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User"])},
    "exportColumnGroupName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group"])},
    "filterPerDay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per day"])},
    "filterPerMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per month"])},
    "tabDevices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CHARGERS"])},
    "tabDevicesEnergy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Energy"])},
    "tabDevicesId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
    "tabDevicesName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "tabRecords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RECORDS"])},
    "tabRecordsCharger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Charger"])},
    "tabRecordsEnd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End"])},
    "tabRecordsEnergy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Energy"])},
    "tabRecordsStart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start"])},
    "tabUsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["USERS"])},
    "tabUsersEnergy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Energy"])},
    "tabUsersName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Records and comsumptions"])},
    "sheetTitle0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General"])},
    "sheetTitle1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Records"])},
    "sheetTitle2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By charger"])},
    "sheetTitle3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By user"])},
    "sheetTitle4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By group"])},
    "sheetGeneralDateFrom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From:"])},
    "sheetGeneralDateTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To:"])},
    "sheetGeneralTotalRecords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total records:"])},
    "sheetGeneralTotalEnergy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total energy (KwH):"])},
    "sheetChargersTableSerialNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serial number"])},
    "sheetChargersTableName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "sheetChargersTableType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
    "sheetUsersTableName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "sheetUsersTableEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])}
  },
  "reset": {
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
    "checkInbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check your mail inbox"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue"])},
    "enterMail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter email"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We have sent you an email with instructions to reset your password."])},
    "wait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please wait! we are processing the request."])}
  },
  "register": {
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
    "checkInbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check your mail inbox"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue"])},
    "enterMail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter email"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We have sent you an email with instructions to reset your password."])},
    "wait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please wait! we are processing the request."])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "confirmPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm password"])}
  },
  "usersTable": {
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users"])}
  },
  "modal": {
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])}
  }
}